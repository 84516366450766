import { Fragment } from 'react'
import {
  BooleanInput,
  SimpleForm,
  TextInput,
  useRecordContext,
} from 'react-admin'
import Grid from '@mui/material/Grid'
import { flatten, uniq, zipObject } from 'lodash'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import BookToolbar from '../../components/BookToolbar'
import GridContainer from '../../components/GridContainer'
import { validateRequired } from '../../utils/validate'
import { camelCaseWithPrefixToHyphen } from '../../utils/case'

export const allPermissions = {
  overall: {
    title: 'Overall',
    permissions: [
      { id: 'publisher-dashboard', name: 'Publisher dashboard' },
      { id: 'publisher-dashboard-admin', name: 'Publisher dashboard admin' },
      { id: 'kpi-dashboards', name: 'KPI Dashboard' },
      {
        id: 'publisher-purchase-histories',
        name: 'Purchase histories (Publisher report)',
      },
    ],
  },
  crm: {
    title: 'CRM',
    permissions: [
      { id: 'user-details', name: 'User detail' },
      {
        id: 'reviews',
        name: 'Reviews',
      },
      {
        id: 'user-credits',
        name: '[CRM] Add credits',
      },
      {
        id: 'cs-user-books',
        name: '[CRM] Add book/ebook',
      },
      {
        id: 'cs-user-subscriptions',
        name: '[CRM] Add subscription',
      },
      {
        id: 'cs-user-codes',
        name: '[CRM] Redeem code',
      },
      {
        id: 'cs-delete-accounts',
        name: '[CRM] Delete account',
      },
      {
        id: 'cs-switch-accounts',
        name: '[CRM] Switch account',
      },
      {
        id: 'cs-merge-accounts',
        name: '[CRM] Merge account',
      },
      {
        id: 'payment-cod',
        name: '[CRM] COD Payment',
      },
      {
        id: 'cs-revert-codes',
        name: '[CRM] Revert code',
      },
    ],
  },
  contentManagement: {
    title: 'Content management',
    permissions: [
      { id: 'category-groups', name: 'Category groups' },
      { id: 'categories', name: 'Categories' },
      {
        id: 'topics',
        name: 'Topics',
      },
      {
        id: 'order-categories',
        name: 'Order categories',
      },
      {
        id: 'books',
        name: 'Books',
      },
      {
        id: 'english-books',
        name: 'English books',
      },
      {
        id: 'courses',
        name: 'Courses',
      },
      {
        id: 'suggestions',
        name: 'Suggestions',
      },
      {
        id: 'ebooks',
        name: 'Ebooks',
      },
      {
        id: 'authors',
        name: 'Authors',
      },
      {
        id: 'coaches',
        name: 'Mentor',
      },
      {
        id: 'book-summaries',
        name: 'Book summaries',
      },
      {
        id: 'meditations',
        name: 'Meditations',
      },
      {
        id: 'guided-meditations',
        name: 'Series Meditation',
      },
      {
        id: 'meditation-quotes',
        name: 'Meditation Quote',
      },
      {
        id: 'sleep-stories',
        name: 'Sleep stories',
      },
      {
        id: 'kids-stories',
        name: 'Kids stories',
      },
      {
        id: 'musics',
        name: 'Musics',
      },
      {
        id: 'podcasters',
        name: 'Podcasters',
      },
      {
        id: 'channels',
        name: 'Channels',
      },
      {
        id: 'episodes',
        name: 'Episodes',
      },
      {
        id: 'configuration-settings',
        name: 'Configuration Settings',
      },
      {
        id: 'landing-page',
        name: 'Landing Page Settings',
      },
      {
        id: 'featured-contents',
        name: 'Featured Contents',
      },
    ],
  },
  affiliate: {
    title: 'Affiliate',
    permissions: [
      { id: 'affiliates', name: 'Affiliates' },
      { id: 'affiliate-collections', name: 'Affiliate Collections' },
      {
        id: 'affiliate-purchase-histories',
        name: 'Affiliate Dashboard',
      },
      {
        id: 'affiliate-payment-orders',
        name: 'Affiliate Web Orders',
      },
    ],
  },
  promotedContent: {
    title: 'Promoted content',
    permissions: [
      { id: 'bundles', name: 'Bundles' },
      {
        id: 'banners',
        name: 'Banners',
      },
      {
        id: 'referral-dashboard',
        name: 'Referral',
      },
      {
        id: 'referral-books',
        name: 'Referral Books',
      },
      {
        id: 'campaigns',
        name: 'Campaigns',
      },
      {
        id: 'promotion-configs',
        name: 'Promotion configs',
      },
    ],
  },
  codeAndVoucher: {
    title: 'Code and voucher',
    permissions: [
      { id: 'batch-codes', name: 'Batch code' },
      {
        id: 'codes',
        name: 'Code',
      },
      {
        id: 'redeem-code',
        name: 'Redeemed code',
      },
      {
        id: 'collection-codes',
        name: 'Collection code',
      },
      {
        id: 'collection-code-transactions',
        name: 'Collection code transactions',
      },
      {
        id: 'discounts',
        name: 'Promotion',
      },
      {
        id: 'transaction-discounts',
        name: 'Promo transactions',
      },
      {
        id: 'redeem-vouchers',
        name: 'Redeem vouchers',
      },
    ],
  },
  account: {
    title: 'Account',
    permissions: [
      { id: 'roles', name: 'Roles' },
      { id: 'users', name: 'Accounts' },
      { id: 'activities', name: 'Activities' },
      {
        id: 'voice-actors',
        name: 'Voice actor',
      },
      {
        id: 'full-access-accounts',
        name: 'Full access accounts',
      },
      {
        id: 'free-accounts',
        name: 'Free accounts',
      },
      {
        id: 'b2b-organizations',
        name: 'B2B Organizations',
      },
      {
        id: 'b2b-organization-contents',
        name: 'B2B Organization Contents',
      },
      {
        id: 'b2b-users',
        name: 'B2B Users',
      },
      {
        id: 'b2b-trial-users',
        name: 'B2B Trial Users',
      },
    ],
  },
  challengeReward: {
    title: 'Challenge Reward',
    permissions: [
      { id: 'challenge-rewards', name: 'Challenge Rewards' },
      { id: 'challenge-reward-redeems', name: 'Challenge Rewards Redeems' },
    ],
  },
}

const additionalPermissions = {
  'publisher-dashboard': [
    'readUsers',
    'publisher-dashboard-revenue',
    'publisher-dashboard-revenue-admin',
    'publisher-dashboard-admin',
  ],
  'publisher-dashboard-admin': [
    'readUsers',
    'publisher-dashboard-revenue-admin',
    'publisher-dashboard-admin',
  ],
  'affiliate-purchase-histories': [
    'affiliate-dashboard-install',
    'affiliate-dashboard-revenue',
    'readUsers',
  ],
  'affiliate-payment-orders': ['affiliate-payment-order-revenue', 'readUsers'],
  users: ['readRoles'],
  affiliates: ['readUsers', 'readBooks'],
  'referral-dashboard': ['referral-dashboard-analytic'],
  'user-credits': ['readUsers'],
  categories: [
    'readUsers',
    'readBooks',
    'readMusics',
    'readSleepStories',
    'readKidsStories',
    'readMeditations',
    'readBookSummaries',
    'readEbooks',
  ],
  topics: ['readUsers'],
  'category-groups': ['readCategories'],
  collections: ['readUsers', 'readBookSummaries', 'readBooks'],
  books: [
    'readUsers',
    'readCategories',
    'topics',
    'readBookSummaries',
    'readEbooks',
    'chapters',
    'authors',
    'voice-actors',
  ],
  'english-books': [
    'readUsers',
    'readCategories',
    'topics',
    'authors',
    'voice-actors',
  ],
  courses: ['readUsers', 'readCategories', 'coaches'],
  banners: [
    'readAuthors',
    'readBooks',
    'readBookSummaries',
    'readChannels',
    'readEpisodes',
  ],
  musics: ['readUsers', 'readCategories'],
  'book-summaries': [
    'readUsers',
    'readCategories',
    'readTopics',
    'readAuthors',
    'readBooks',
    'readEbooks',
  ],
  ebooks: [
    'readUsers',
    'readCategories',
    'readTopics',
    'readAuthors',
    'readBooks',
    'readBookSummaries',
  ],
  meditations: ['readUsers', 'readCategories'],
  'sleep-stories': ['readUsers', 'readCategories', 'readTopics'],
  'kids-stories': ['readUsers', 'readCategories', 'readTopics'],
  'guided-meditations': ['readUsers', 'readCategories'],
  'meditation-backgrounds': ['readUsers'],
  'meditation-quotes': ['readUsers'],
  reviews: ['readUsers', 'readBooks', 'readCourses'],
  suggestions: ['readUsers'],
  'referral-books': ['readUsers', 'readBooks'],
  'publisher-purchase-histories': ['readUsers'],
  'free-accounts': ['readUsers'],
  'full-access-accounts': ['readUsers'],
  discounts: ['readUsers'],
  'transaction-discounts': [
    'readUsers',
    'readDiscounts',
    'transaction-discounts-revenue',
  ],
  codes: ['readUsers', 'readBatchCodes'],
  'batch-codes': ['readUsers', 'readBooks'],
  'collection-codes': ['readUsers', 'readBooks'],
  'collection-code-transactions': ['readCollectionCodes'],
  'redeem-code': ['readUsers', 'readBatchCodes'],
  'cs-delete-accounts': ['readUsers'],
  'cs-switch-accounts': ['readUsers'],
  'cs-merge-accounts': ['readUsers'],
  'cs-revert-codes': ['codes'],
  bundles: ['readBooks'],
  campaigns: ['readBooks'],
  podcasters: ['readUsers', 'readChannels'],
  channels: ['readUsers', 'readPodcasters', 'readCategories', 'readEpisodes'],
  episodes: ['readChannels'],
  'challenge-rewards': ['readUsers', 'readBooks'],
  'challenge-reward-redeems': ['readUsers', 'readBooks'],
  'b2b-organizations': ['readUsers', 'readBooks', 'readCourses'],
  'b2b-organization-contents': [
    'readUsers',
    'readBooks',
    'readCourses',
    'readB2bOrganizations',
  ],
  'b2b-users': ['readB2bOrganizations'],
  'b2b-trial-users': ['readUsers', 'readB2bOrganizations'],
}
export const transformDataBeforeSubmit = values => {
  const { permission = {}, ...rest } = values

  // To keep origin orders
  const selectedPermissions = flatten(
    Object.keys(allPermissions).map(key => allPermissions[key].permissions),
  )
    .map(p => p.id)
    .filter(p => !!permission[p])

  const additional = flatten(
    selectedPermissions.map(key => additionalPermissions[key]).filter(Boolean),
  )

  const manageAdditional = additional.filter(
    p => !p.startsWith('read') && !selectedPermissions.includes(p),
  )

  const manage = [...selectedPermissions, ...manageAdditional]

  const read = additional
    .filter(p => p.startsWith('read'))
    .map(p => camelCaseWithPrefixToHyphen(p, 'read'))
    .filter(p => !manage.includes(p))

  return {
    ...rest,
    permission: {
      can: { manage, read: uniq(read) },
    },
  }
}

const transformDataBeforeRenderForm = record => {
  if (!record) return {}

  const { permission = {}, ...rest } = record
  const { manage = [] } = permission.can || {}

  return {
    ...rest,
    permission: zipObject(manage, Array(manage.length).fill(true)),
  }
}

const RoleForm = ({ isCreating, ...props }) => {
  const record = useRecordContext()

  return (
    <SimpleForm
      {...props}
      toolbar={<BookToolbar />}
      redirect="list"
      variant="standard"
      margin="normal"
      defaultValues={transformDataBeforeRenderForm(record)}>
      <GridContainer>
        <Grid item xs={12}>
          <TextInput
            source="name"
            fullWidth
            variant="standard"
            validate={[validateRequired('Name')]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            source="id"
            fullWidth
            label="code"
            variant="standard"
            validate={[validateRequired('Code')]}
            disabled={!isCreating}
          />
        </Grid>
        <Grid item xs={4}>
          {['overall', 'crm', 'account'].map(key => {
            const item = allPermissions[key]
            return (
              <Fragment key={key}>
                <Typography variant="h6">{item.title}</Typography>
                <Box height={10} />
                {item.permissions.map(p => {
                  return (
                    <BooleanInput
                      key={JSON.stringify(p)}
                      type="checkbox"
                      source={`permission.${p.id}`}
                      label={p.name}
                    />
                  )
                })}
              </Fragment>
            )
          })}
        </Grid>
        <Grid item xs={4}>
          {['contentManagement'].map(key => {
            const item = allPermissions[key]
            return (
              <Fragment key={key}>
                <Typography variant="h6">{item.title}</Typography>
                <Box height={10} />
                {item.permissions.map(p => {
                  return (
                    <BooleanInput
                      key={JSON.stringify(p)}
                      type="checkbox"
                      source={`permission.${p.id}`}
                      label={p.name}
                    />
                  )
                })}
              </Fragment>
            )
          })}
        </Grid>
        <Grid item xs={4}>
          {[
            'affiliate',
            'promotedContent',
            'codeAndVoucher',
            'challengeReward',
          ].map(key => {
            const item = allPermissions[key]
            return (
              <Fragment key={key}>
                <Typography variant="h6">{item.title}</Typography>
                <Box height={10} />
                {item.permissions.map(p => {
                  return (
                    <BooleanInput
                      key={JSON.stringify(p)}
                      type="checkbox"
                      source={`permission.${p.id}`}
                      label={p.name}
                    />
                  )
                })}
              </Fragment>
            )
          })}
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default RoleForm
