import {
  Datagrid,
  DateField,
  DateInput,
  List,
  ReferenceInput,
  SelectInput,
  TextField,
  ExportButton,
  FunctionField,
  SelectArrayInput,
  AutocompleteInput,
  BooleanInput,
  useListContext,
} from 'react-admin'
import moment from 'moment'

import FilterComponent from '../../components/Filter'
import { exportExcel } from '../../utils/exportExcel'
import { FILTER_DATE, ROLES } from '../../components/constants'

export const PURCHASE_TYPES = [
  {
    id: 'free',
    name: 'Free',
  },
  {
    id: 'direct',
    name: 'Direct',
  },
  {
    id: 'credit',
    name: 'Credit',
  },
  {
    id: 'code',
    name: 'Code',
  },
  {
    id: 'collection',
    name: 'Collection',
  },
  {
    id: 'membership_included',
    name: 'Membership Included',
  },
  {
    id: 'manual',
    name: 'Manual',
  },
  {
    id: 'affiliate',
    name: 'Affiliate',
  },
  {
    id: 'referral',
    name: 'Referral',
  },
]

const renderBookTitle = record => {
  if (record.category === 'english_book') {
    return (
      (record.englishBook && record.englishBook.title) ||
      (record.english_book && record.english_book.title)
    )
  }
  return record.book && record.book.title
}

const renderPurchaseType = record => {
  if (record.type === 'credit') {
    return `credit (${record.typeCredit})`
  }
  return record.type
}

const renderCountry = record => {
  return currencyCountryMap[record.currency] || 'VN'
}

const exporter = records => {
  const data = records.map(record => {
    return {
      Publisher: (record.publisher && record.publisher.name) || '',
      'Publisher type': record.publisherType,
      'Book id': record.entityId,
      'Book title': renderBookTitle(record),
      'Purchase date': moment(record.createdAt).format('MM/DD/YYYY'),
      'Content type': record.category,
      'Purchase type': renderPurchaseType(record),
      Country: renderCountry(record),
      'Exchange rate': record.exchangeRate,
      'Price in currency': record.priceInCurrency,
      'Original price (1)': record.price,
      Discount: record.discount,
      'Discount type': record.discountType,
      'Actual price (2)': record.actualPrice,
      '% Tax fee': record.taxFeePercentage,
      'Tax excluded price (4)': record.taxExcludedPrice,
      '% Platform fee': record.platformFeePercentage,
      'Net price (3)': record.netPrice,
      'Final price': record.finalPrice,
      '% Commission': record.commission,
      'Gross commission': record.grossCommission,
    }
  })
  exportExcel({
    data,
    title: 'PurchaseHistories',
    type: 'xlsx',
  })
}

const currencyCountryMap = {
  USD: 'US',
  JPY: 'Japan',
  AUD: 'Australia',
  KRW: 'Korea',
  VND: 'VN',
}

const Actions = ({ resource, currentSort, filterValues, exporter }) => {
  return (
    <ExportButton
      disabled={false}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={1000000}
    />
  )
}

const CustomFilter = props => {
  const { filterValues } = useListContext()
  return (
    <FilterComponent {...props}>
      <ReferenceInput
        label={'Publisher'}
        source={'publisherId'}
        perPage={1000}
        reference="users"
        alwaysOn
        allowEmpty
        filter={{ role: ROLES.PUBLISHER }}>
        <AutocompleteInput
          optionText="name"
          options={{
            fullWidth: true,
          }}
          filterToQuery={searchText => ({
            name: { $iLike: `%${searchText}%` },
            role: ROLES.PUBLISHER,
          })}
        />
      </ReferenceInput>
      <SelectInput
        label="Filter"
        source="date"
        choices={FILTER_DATE}
        alwaysOn
        allowEmpty={false}></SelectInput>
      {'custom' === filterValues.date && (
        <DateInput label="From date" source="fromDate" alwaysOn></DateInput>
      )}
      {'custom' === filterValues.date && (
        <DateInput label="To date" source="toDate" alwaysOn></DateInput>
      )}
      <ReferenceInput label="Book" source="bookId" reference="books" alwaysOn>
        <AutocompleteInput
          optionText="title"
          filterToQuery={searchText => ({
            title: { $iLike: `%${searchText}%` },
          })}
        />
      </ReferenceInput>
      <SelectArrayInput
        label="Purchase types"
        source="types"
        choices={PURCHASE_TYPES}
        alwaysOn
        style={{ minWidth: '300px' }}
      />
      <BooleanInput
        label="Exclude free credits"
        source="excludeFreeCredits"
        alwaysOn
      />
    </FilterComponent>
  )
}

const PurchaseHistoryList = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      filters={<CustomFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<Actions />}
      filterDefaultValues={{
        date: FILTER_DATE[0].id,
        fromDate: moment()
          .subtract(7, 'days')
          .startOf('day')
          .format('MM-DD-YYYY'),
        toDate: moment().endOf('day').format('MM-DD-YYYY'),
      }}>
      <Datagrid bulkActionButtons={false} rowClick={false} {...props}>
        <TextField label="Publisher" source="publisher.name" />
        <TextField label="Publisher type" source="publisherType" />
        <TextField label="Book id" source="entityId" sortable={false} />
        <FunctionField
          label="Book title"
          style={{ width: 200, display: 'block' }}
          render={renderBookTitle}
        />
        <DateField label="Purchase date" source="createdAt" />
        <TextField label="Content type" source="category" />
        <FunctionField label="Purchase type" render={renderPurchaseType} />
        <FunctionField
          label="Country"
          style={{ whiteSpace: 'nowrap' }}
          render={renderCountry}
        />
        <TextField
          label="Exchange rate"
          source="exchangeRate"
          sortable={false}
        />
        <TextField
          label="Price in currency"
          source="priceInCurrency"
          sortable={false}
        />
        <TextField label="Original price (1)" source="price" sortable={false} />
        <TextField label="Discount" source="discount" sortable={false} />
        <TextField
          label="Discount type"
          source="discountType"
          sortable={false}
        />
        <TextField
          label="Actual price (2)"
          source="actualPrice"
          sortable={false}
        />
        <TextField
          label="% Tax fee"
          source="taxFeePercentage"
          sortable={false}
        />
        <TextField
          label="Tax excluded price (4)"
          source="taxExcludedPrice"
          sortable={false}
        />
        <TextField
          label="% Platform fee"
          source="platformFeePercentage"
          sortable={false}
        />
        <TextField label="Net price (3)" source="netPrice" sortable={false} />
        <TextField label="Final price" source="finalPrice" sortable={false} />
        <TextField source="commission" label="% Commission" sortable={false} />
        <TextField
          source="grossCommission"
          label="Gross commission"
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}

export default PurchaseHistoryList
